import Gem from "./Gem";
import { Gem as GemModel } from "./useGems";

import GemUrl from "./assets/spta/gem1.png";

import "./Gems.css";

interface GemsProps {
  gems: GemModel[];
  gemCounter: number;
  onTakeGem: (gemId: number) => void;
  dinoRef: React.RefObject<HTMLDivElement>;
  timeToDisplay: string;
}

const Gems = ({
  gems,
  gemCounter,
  onTakeGem,
  dinoRef,
  timeToDisplay,
}: GemsProps) => {
  return (
    <>
      <div className="counter-container">
        <div className="gem-counter">
          <img src={GemUrl} alt="" />
          <span>{gemCounter}</span>
        </div>
        <div className="counter">
          <span>{timeToDisplay}</span>
        </div>
      </div>
      <div className="gems-wrapper">
        {gems.map((gem) => (
          <Gem
            key={gem.id}
            id={gem.id}
            bottom={gem.bottom}
            left={gem.left}
            imageUrl={gem.imageUrl}
            dinoRef={dinoRef}
            onTakeGem={onTakeGem}
            isTaken={gem.isTaken}
            artist={gem.artist}
          />
        ))}
      </div>
    </>
  );
};

export default Gems;
