import Sky from "./Sky";
import Hills from "./Hills";
import Gems from "./Gems";
import Dino from "./Dino";
import Ground from "./Ground";
import Train from "./Train";
import Splash from "./Splash";

import useGame from "./useGame";
import useTrain from "./useTrain";
import useDino from "./useDino";

import "./App.css";
import DinoFamily from "./DinoFamily";
import CountdownLoading from "./Countdown";
import { useEffect, useState } from "react";

import Ambiance from "./assets/spta/sound/bo.mp3";
import useGems from "./useGems";
import { useAudio } from "./context/audioContext";

interface Props {
  onStartGameWrapper: () => void;
  onFinishGameWrapper: (gems: number) => void;
  timeToDisplay: string;
}

const GameWrapper = ({
  onStartGameWrapper,
  onFinishGameWrapper,
  timeToDisplay,
}: Props) => {
  const {
    isCountdownVisible,
    setIsCountdownVisible,
    isGameFinished,
    onFinishGame,
    isMusicPlaying,
    setIsMusicPlaying,
  } = useGame();

  const { isTrainGoing, onStartTrain, onStopTrain, trainRef } = useTrain();

  const {
    dinoState,
    dinoPosition,
    dinoSpeed,
    isDinoFlipping,
    isDinoOnTrain,
    startDino,
    stopDino,
    dinoRef,
  } = useDino({ isGameFinished, trainRef });

  const { gems, gemCounter, onTakeGem } = useGems({
    dinoSpeed,
    onStartTrain,
    onFinishGame,
    isDinoOnTrain,
  });
  const { audio } = useAudio();

  useEffect(() => {
    if (isCountdownVisible === true) {
      onStartGameWrapper();
    }
  }, [isCountdownVisible]);

  useEffect(() => {
    if (isGameFinished === true) {
      onFinishGameWrapper(gemCounter);
    }
  }, [isGameFinished, dinoState]);

  const onCountdownHide = () => {
    startDino();
    startBackgroundMusic();
  };

  const startBackgroundMusic = () => {
    if (isMusicPlaying === true || audio === undefined) return;
    setIsMusicPlaying(true);

    audio.loop = true;
    audio.volume = 0.1;
    audio.play();
  };

  useEffect(() => {
    if (timeToDisplay === "01:00") {
      if (audio !== undefined) {
        audio.pause();
      }

      onFinishGame();
    }
  }, [audio, onFinishGame, timeToDisplay]);

  return (
    <>
      <div className="game-canvas">
        <div>
          <Sky dinoSpeed={dinoSpeed} />
          <Hills dinoSpeed={dinoSpeed} />
          {isTrainGoing && (
            <Train
              dinoSpeed={dinoSpeed}
              trainRef={trainRef}
              onStopTrain={onStopTrain}
            />
          )}
          <Gems
            gems={gems}
            gemCounter={gemCounter}
            onTakeGem={onTakeGem}
            dinoRef={dinoRef}
            timeToDisplay={timeToDisplay}
          />
          <Dino
            state={dinoState}
            position={dinoPosition}
            isFlipping={isDinoFlipping}
            dinoRef={dinoRef}
            isMain
          />
          {isGameFinished && (
            <DinoFamily dinoSpeed={dinoSpeed} stopDino={stopDino} />
          )}
        </div>
        <div>
          <Ground dinoSpeed={dinoSpeed} />
        </div>
      </div>
      <CountdownLoading onHide={onCountdownHide} />
    </>
  );
};

export default GameWrapper;
