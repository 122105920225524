import "./Leaderboard.css";
import { User } from "../models/User";

interface Props {
  user: User;
  updateUser: any;
  onRestartClicked: () => void;
}

export const Leaderboard = ({ user, onRestartClicked }: Props) => {
  return (
    <div className="wrapper">
      <div className="container">
        <h1>Bravo</h1>
        <h2>Tu as bien joué, ton score est {String(user.score)}</h2>

        <div style={{ marginTop: 20 }}>
          <button onClick={onRestartClicked} className="button">
            Re-jouer
          </button>
        </div>
      </div>
    </div>
  );
};
