import "./Welcome.css";

interface WelcomeProps {
  onPlayPressed: () => void;
}

export const Welcome = ({ onPlayPressed }: WelcomeProps) => {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="setp-1">
          <h1 style={{ marginBottom: 20 }}>Règles du jeu</h1>
          <p>Tu as 1mn pour récupérer le maximum de pieces.</p>
          <div style={{ marginTop: 20 }}>
            <button onClick={onPlayPressed} className="button">
              Jouer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
